<template>
  <div class="section">
    <swiper
      :slides-per-view="1"
      :space-between="30"
      :loop="true"
      :pagination="swiperConfig"
    >
      <swiper-slide>
        <div class="main-photo">
          <img src="../../assets/images/slide-1.jpg" alt="" />
        </div>
        <div data-index="1" class="slides-item">
          <h1 class="slides-item__title">掃描開始充電</h1>
          <p class="slides-item__desc">開啟相機掃描 QR Code，立即開始充電</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="main-photo">
          <img src="../../assets/images/slide-2.jpg" alt="" />
        </div>
        <div data-index="2" class="slides-item">
          <h1 class="slides-item__title">即時充電通知</h1>
          <p class="slides-item__desc">設定充電電量通知，隨時掌握最新資訊</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="main-photo">
          <img src="../../assets/images/slide-3.jpg" alt="" />
        </div>
        <div data-index="3" class="slides-item">
          <h1 class="slides-item__title">超高速充電站</h1>
          <p class="slides-item__desc">顯示最近的充電站 查看即時資訊</p>
        </div>
      </swiper-slide>

      <ul class="controller"></ul>
    </swiper>
  </div>
</template>
<script>
import { Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination]);

export default {
  name: "BindingSuccessMessage.vue",
  props: {},
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperConfig: {
        el: ".controller",
        clickable: true,
        renderBullet: function (index, className) {
          return '<li class="' + className + '"></li>';
        },
      },
    };
  },
  methods: {},
  watch: {},
};
</script>