<template>
  <main id="full" class="binding">
    <section class="section-top point">
      <h1 class="section-top-title">充電卡綁定成功</h1>
    </section>
    <section class="">
      <div class="section-main__action my-0">
        <BindingSuccessMessage />
        <template>
          <a
            @click.stop="checkAppIsExist"
            id="appSchema"
            class="button is-rounded"
          >
            在 U-POWER App 上查看
          </a>
        </template>
      </div>
    </section>
  </main>
</template>
<script>
import BindingSuccessMessage from "../../components/Messages/BindingSuccessMessage.vue";

export default {
  name: "BindingSuccess",
  components: { BindingSuccessMessage },
  data() {
    return {
      userAgent: "",
    };
  },
  async created() {
    this.checkDeviceOs();
  },
  mounted() {
    if (this.$route.params.isVerify == undefined) {
      this.$router.push({
        name: "CardLink",
      });
      return;
    }
  },
  methods: {
    checkAppIsExist() {
      const openAppUrl = "upower://";
      const googlePlayUrl =
        "https://play.google.com/store/apps/details?id=com.u_power";
      const appStoreUrl = "https://apps.apple.com/tw/app/u-power/id1612379206";

      // 避免啟動失敗的計時器
      var timer = setTimeout(() => {
        if (this.userAgent === "Android") {
          window.location.href = googlePlayUrl;
          return;
        }
        if (this.userAgent === "IOS") {
          window.location = appStoreUrl;
          return;
        }
      }, 1000);

      if (this.userAgent === "Android") {
        window.location.href = openAppUrl;
      }
      if (this.userAgent === "IOS") {
        window.clearTimeout(timer);
        window.location = appStoreUrl;
      }

      // 用 onblur 來確認有沒有多跳一個是否啟動 APP 的確認視窗
      if (this.userAgent === "Android") {
        window.onblur = () => {
          window.clearTimeout(timer);
          window.close();
        };
      }
    },
    checkDeviceOs() {
      let userAgent = navigator.userAgent;

      if (userAgent.match(/Windows Phone/i)) {
        // Windows Phone
        this.userAgent = "Android";
        return;
      } else if (userAgent.match(/Android/i)) {
        if (userAgent.match(/Chrome/i)) {
          // Android + Chrome
          setTimeout(() => {
            if (!document.webkitHidden) {
              this.userAgent = "Android";
              return;
            }
          }, 1000);
        } else {
          // Android + 非 Chrome
          this.userAgent = "Android";
          return;
        }
      } else if (userAgent.match(/(iPhone|iPad|iPod)/i)) {
        setTimeout(() => {
          this.userAgent = "IOS";
          return;
        }, 25);
      } else if (userAgent.match(/(Macintosh|Mac)/i)) {
        setTimeout(() => {
          this.userAgent = "IOS";
          return;
        }, 25);
      } else {
        //其他
        let loadDateTime = new Date();
        setTimeout(() => {
          let timeOutDateTime;
          timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            this.userAgent = "Android";
            return;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style>
#appSchema {
}
</style>
